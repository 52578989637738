import * as React from "react";

function CheckmarkIcon({ fill = "#fafafa" }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={32}
			height={24}
			viewBox="0 0 32 24"
			fill="none"
		>
			<path
				d="M31.219 5.219L13.885 22.552a2.66 2.66 0 01-1.885.782 2.66 2.66 0 01-1.885-.782l-8.667-8.667a2.663 2.663 0 010-3.77 2.663 2.663 0 013.77 0L12 16.896 27.448 1.448a2.663 2.663 0 013.77 0 2.664 2.664 0 010 3.77z"
				fill={fill}
			/>
		</svg>
	);
}

export default CheckmarkIcon;
